<!--智能扩图吧-->

<template>
  <section class="file-upload">
    <div class="fileupload-container">

      <div class="c1">
        <div style="width: 100%;">
          <div style="float: right;margin: 6px 12px 10px 6px;">
            <div style="display:inline-block">
            </div>
          </div>
        </div>
      </div>

      <div class="c2">
        <div style="margin-bottom:2px">
          <h1 class="title">智能扩图吧</h1>
          <transition name="fade">
            <img v-bind:src=bannerImage
                 class="effect-img" alt="智能扩图吧 - 在线AI扩图工具"
                 style="border: 1px solid #f1f1f1;">
          </transition>
        </div>

        <h3 class="tips">
          <transition name="fade">
            <span>{{ tip }}</span>
          </transition>
          <a v-if="!isQuantityMembership()" class="link-button" v-on:click="routeToPointPackage"
             style="margin-left:9px;">加入会员</a>
          <!-- <a class="link-button" v-on:click="toggleShowAdvancedOptions">高级选项</a>-->
        </h3>

        <div v-if="isAdvancedOptionsVisible" class="advanced-container">
          <transition name="fade">
            <div class="fade-container">
              <!--选择尺寸-->
              <div class="outpaint-method">
                <span class="item" @click="onClickItem('CUSTOM')">
                  自定义
                  <span class="custom-size-container">
                    <b-form-input type="number" class="custom-width" v-model="outputImageWidth" placeholder="宽"
                                  size="sm" @blur="onCustomWidthBlur" min="1" max="3840"
                                  autocomplete="off"></b-form-input>
                    <b-form-input type="number" class="custom-height" v-model="outputImageHeight" placeholder="高"
                                  size="sm" @blur="onCustomHeightBlur" min="1" max="3840"
                                  autocomplete="off"></b-form-input>
                  </span>
                </span>
                <span class="item" @click="onClickItem('ORIGINAL_RATIO')">原比例</span>
                <span class="item active" @click="onClickItem('RATIO_1_1')">1:1</span>
                <span class="item" @click="onClickItem('RATIO_3_2')">3:2</span>
                <span class="item" @click="onClickItem('RATIO_2_3')">2:3</span>
                <span class="item" @click="onClickItem('RATIO_4_3')">4:3</span>
                <span class="item" @click="onClickItem('RATIO_3_4')">3:4</span>
                <span class="item" @click="onClickItem('RATIO_16_9')">16:9</span>
                <span class="item" @click="onClickItem('RATIO_9_16')">9:16</span>
                <span class="item" @click="onClickItem('RATIO_1920x1080')">1920x1080</span>
                <span class="item" @click="onClickItem('RATIO_1080x1920')">1080x1920</span>
                <span class="item" @click="onClickItem('RATIO_3840x2160')">3840x2160</span>
                <span class="item" @click="onClickItem('RATIO_2160x3840')">2160x3840</span>
                <span class="item" @click="onClickItem('RATIO_800x800')">800x800</span>
              </div>
              <!--设置宽高占比-->
              <div style="display: flex;justify-content:space-between; gap: 15px;font-size: 13px;margin-bottom:12px;">
                <div style="width:100%;border: 1px solid #e1e1e1;border-radius: 8px;padding: 5px 10px 0 10px;">
                  <div>宽度分配</div>
                  <b-form-input type="range" class="custom-width" v-model="padRightRatio" min="0" max="100" step="1"
                                size="sm"></b-form-input>
                  <div style="display: flex;justify-content:space-between;position: relative;top: -5px;font-size:12px;">
                    <div>左扩 {{ padLeftRatio }}%</div>
                    <div>右扩 {{ padRightRatio }}%</div>
                  </div>
                </div>
                <div style="width:100%;border: 1px solid #e1e1e1;border-radius: 8px;padding: 5px 10px 0 10px;">
                  <div>高度分配</div>
                  <b-form-input type="range" class="custom-height" v-model="padBottomRatio" min="0" max="100" step="1"
                                size="sm"></b-form-input>
                  <div style="display: flex;justify-content:space-between;position: relative;top: -5px;font-size:12px;">
                    <div>上扩 {{ padTopRatio }}%</div>
                    <div>下扩 {{ padBottomRatio }}%</div>
                  </div>
                </div>
              </div>
              <!--生成张数-->
              <div class="generated-image-number-container">
                <div class="generated-image-number-wrapper">
                  <span class="label">生成张数：</span>
                  <span class="value">{{ imageNumber }}</span>
                  <b-form-input class="input" v-model="imageNumber" type="range" min="1"
                                max="4"></b-form-input>

                  <b-form-checkbox-group size="sm" style="display:inline-block;font-size:13px;margin-left:30px;"
                                         v-model="enablePromptValue"
                                         :options="enablePromptOptions"
                                         @change="uploadOptionChange"
                                         name="enable-prompt-checkbox">
                  </b-form-checkbox-group>

                </div>
              </div>
              <!--提示词-->
              <div v-if="enablePromptValue.indexOf('ENABLE_PROMPT')!==-1" class="prompt-section">
                <div class="prompt-wrapper">
                  <b-form-textarea class="prompt-input" v-model="prompt"
                                   size="sm" placeholder="输入提示词"
                                   v-on:keydown="promptKeyDown()"
                                   v-on:keyup="promptKeyUp()">
                  </b-form-textarea>
                  <div style="text-align:right;padding:0 8px;font-size:13px;">
                    <span style="margin-right:12px;color:#aba9a9">{{ promptCount }} / {{ promptCountLimit }}</span>
                    <span class="icon-trash-2 icon" v-on:click="clearPrompt"></span>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>

        <div class="pond-container">

          <!-- 单图上传控件-->
          <div class="pond" v-bind:class="{ hidden:isMultipleUploading }">
            <file-pond name="file" ref="pond"
                       accepted-file-types="image/jpeg, image/png, image/webp, image/bmp, image/tiff"
                       allow-remove="true"
                       allow-revert="false"
                       allow-process="false"
                       instant-upload="false"
                       server=""
                       allow-multiple="true"
                       max-file-size="30MB"
                       v-bind:max-files="maxFiles"
                       v-bind:disabled="disabled"
                       v-bind:files="myFiles"
                       v-on:init="onInit"
                       v-on:initfile="onInitFile"
                       v-on:addfile="onAddfile"
                       v-on:processfile="onProcessfile"
                       v-on:removefile="onRemovefile"
                       v-on:warning="onWarning"
                       v-on:error="onError"
                       credits="false"
            />
          </div>

          <!-- 多图上传控件-->
          <div class="pond-multiple" v-bind:class="{ hidden:!isMultipleUploading }">
            <div class="filepond--wrapper">
              <div class="filepond--root filepond--hopper"
                   data-style-button-remove-item-align="false" style="height: 76px;">

                <div class="filepond--drop-label">
                  <label
                      v-bind:class="{ 'error-message':(messageType==='GENERIC_BATCH_UPLOAD_ERROR') }">
                    {{ batchUploadProgressMessage }}
                  </label>
                  <span class="filepond--label-action"
                        v-if="messageType==='GENERIC_BATCH_UPLOAD_ERROR'"
                        v-on:click="resetUpload" style="margin-left:5px;">重新上传</span>
                  <!-- 除非终止操作能控制GPU服务的停止，否则仅在阿里云端终止是没有任何意义的，所以此功能已弃用，
                       弃用用也不用担心用户的任务一直卡住导致无法处理新任务，因为有超时机制。这段代码不要删，供学习用！
                  <span class="filepond&#45;&#45;label-action"
                        v-if="messageType==='GENERIC_BATCH_UPLOAD_REFUSE'"
                        v-on:click="abortUploaded" style="color:#db2828;">终止</span>
                  <span class="filepond--label-action" v-if="messageType==='GENERIC_BATCH_UPLOAD_REFUSE'"
                        v-on:click="resetUpload" style="color:#9e9e9e;">等待</span>
                  -->
                </div>
                <div class="filepond--panel filepond--panel-root" data-scalable="true">
                  <div class="filepond--panel-top filepond--panel-root"></div>
                  <div class="filepond--panel-center filepond--panel-root"
                       style="transform: translate3d(0px, 8px, 0px) scale3d(1, 0.6, 1);"></div>
                  <div class="filepond--panel-bottom filepond--panel-root"
                       style="transform: translate3d(0px, 68px, 0px);"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="response-message">
            <div>
              <!-- GENERIC_INFO 显示-->
              <div v-if="messageType==='GENERIC_INFO'" style="color:#198f35">
                <div>
                  <b-icon icon="three-dots" animation="cylon" font-scale="3"></b-icon>
                </div>
                <div><span> {{ message }}</span></div>
              </div>
              <!-- GENERIC_ERROR 显示-->
              <div v-else-if="messageType==='GENERIC_ERROR'" style="color:#db2828;">
                <div style="margin-top:28px;">
                  <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                  {{ message }}
                  <span v-if="isShowResetButton" class="reset-upload" v-on:click="resetUpload"
                        style="margin-left:18px;">重新上传</span>
                </div>
              </div>
              <!-- GENERIC_ANONYMOUS_USER_UPLOAD_STATUS 显示-->
              <div v-else-if="messageType==='GENERIC_ANONYMOUS_USER_UPLOAD_STATUS'" style="color:#db2828;">
                <div v-if="message" style="margin-top:28px;">
                  <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
                  {{ message }}
                </div>
              </div>
              <!-- LOCAL_SERVICE_UNAVAILABLE 显示（LOCAL_ 开头的消息是前端仅有的）-->
              <div v-else-if="messageType==='LOCAL_SERVICE_UNAVAILABLE'" style="color:#db2828;">
                <div style="margin-top:28px;">
                  <span v-html="message"></span>
                </div>
              </div>
              <!-- LOCAL_DOWNLOAD_FAILED 显示（LOCAL_ 开头的消息是前端仅有的）-->
              <div v-else-if="messageType==='LOCAL_DOWNLOAD_FAILED'">
                <div style="text-align:center;color:#4f4f4f;margin-top:28px;">
                  <span v-if="!alternateDownloadAddress">
                      <span v-if="isLoadingAlternateDownloadAddress">
                        <b-icon icon="arrow-clockwise" animation="spin" font-scale="1.27"
                                style="position:relative;top:2px;"></b-icon>
                        获取备用地址......
                      </span>
                      <span v-else style="cursor:pointer;" v-on:click="loadAlternateDownloadAddress()">下载失败？</span>
                  </span>
                  <span v-if="alternateDownloadAddress" class="download-link"
                        v-on:click="doAlternateDownloadFile()">点击备用下载
                      <b-icon icon="download" font-scale="1" style="margin:0 3px;"></b-icon>
                  </span>
                </div>
              </div>

              <!-- GENERIC_BATCH_UPLOAD_PROGRESS 显示-->
              <div v-else-if="messageType==='GENERIC_BATCH_UPLOAD_PROGRESS'"
                   style="color:#198f35">
                <div>
                  <b-icon icon="three-dots" animation="cylon" font-scale="3"></b-icon>
                </div>
              </div>
              <!-- GENERIC_BATCH_UPLOAD_SUCCESS 显示-->
              <div v-else-if="messageType==='GENERIC_BATCH_UPLOAD_SUCCESS'" style="color:#00827c">
                <div>
                  <b-icon icon="three-dots" animation="cylon" font-scale="3"></b-icon>
                </div>
              </div>
              <!-- GENERIC_BATCH_PROCESS_WAITING 显示-->
              <div v-else-if="messageType==='GENERIC_BATCH_PROCESS_WAITING'"
                   style="color:#198f35">
                <div>
                  <b-icon icon="three-dots" animation="cylon" font-scale="3"></b-icon>
                </div>
                <div v-html="message"></div>
              </div>
              <!-- GENERIC_BATCH_PROCESS_PROGRESS 显示-->
              <div v-else-if="messageType==='GENERIC_BATCH_PROCESS_PROGRESS'"
                   style="color:#198f35">
                <div>
                  <b-icon icon="three-dots" animation="cylon" font-scale="3"></b-icon>
                </div>
                <div v-html="message"></div>
              </div>
              <!-- GENERIC_BATCH_PROCESS_RESULT 显示-->
              <div v-else-if="messageType==='GENERIC_BATCH_PROCESS_RESULT'" style="color:#155724">
                <div style="margin-top:28px;color:#178d88;">
                  图片 <span style="color:#db2828;font-size:12px;">{{ batchResult.finishedTime }}</span>
                  <span v-if="batchResult.successCount>0"> 就绪</span>
                  <span v-if="batchResult.successCount===0"> 结束</span>
                  ，成功 <span style="font-size:13px;">{{ batchResult.successCount }}</span>
                  失败
                  <span style="font-size:13px;"
                        v-bind:class="{ 'error-message-underline':batchResult.errorCount>0 }"
                        v-on:click="makeToast('danger')">{{ batchResult.errorCount }}
                  </span>
                  <span v-if="batchResult.nsfwCount>0" style="margin-left:2px;">
                  不雅 <span style="font-size:13px;">{{ batchResult.nsfwCount }}</span>
                  </span>

                  <span v-if="batchResult.compressionInfo">，压缩 <span
                      style="font-size:13px;">{{ batchResult.compressionInfo }}</span></span>

                  <span class="download-link"
                        v-if="batchResult.successCount!==0&&(batchResult.url||batchResult.OssUrl)"
                        v-on:click="doDownloadFile()">，点击下载&nbsp;
                                            <b-icon icon="download" font-scale="1.2"
                                                    style="margin-left:2px;"></b-icon>
                                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--预览图-->
        <div v-if="messageType==='GENERIC_BATCH_PROCESS_RESULT'">
          <div class="thumbnail-preview-list">
            <div class="thumbnail-container" v-for="(thumbnailUrl,i) of batchResult.thumbnailUrls" :key="i">
              <img class="thumbnail" v-bind:src="thumbnailUrl" alt="" height="130">
            </div>
          </div>
        </div>

      </div>

      <div class="c3">
        <div class="ads"></div>
      </div>
      <div class="c4"></div>
      <div class="c5"></div>
    </div>
    <div :style="{height:screenHeight}"></div>
  </section>
</template>

<script>
import $ from 'jquery';
//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap/dist/js/bootstrap.min.js'

// 设置默认语言
import * as FilePond2 from 'filepond';
import zh_CN from 'filepond/locale/zh-cn.js';

FilePond2.setOptions(zh_CN);

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately
// 可用插件地址：https://github.com/pqina/filepond

// Import image preview plugin styles
//import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import ImageFile from "@/utils/imagefile";
import common from "@/utils/common";
import UploadCommon from "@/utils/upload.common";
//import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// 设置产品变量
let productCategory = 'IMAGE_OUTPAINT'  //产品类型
let maxFileSizeMBOfAnonymousUser = 5  //免费版文件上传限制，单位MB，999表示不限制
let maxFileSizeMBOfMembership = 30  //会员文件上传限制，单位MB，999表示不限制
let reduceImageWidth = 3840
let reduceImageHeight = 2160

// Vue 本组件内有效
let self = null;
let FilePond = vueFilePond(FilePondPluginFileValidateSize, FilePondPluginFileValidateType); // Vue 本组件内有效

export default {
  name: productCategory,
  data() {
    return {
      screenHeight: (screen.height - 500) + 'px',
      bannerImage: '',
      similarImage: '',
      myFiles: [],
      disabled: true,

      message: '',
      messageType: '',
      isShowResetButton: false,

      multipleUploading: false,

      batchResult: null,
      batchUploadProgressMessage: '',  // 多图上传控件上显示的进度信息
      alternateDownloadAddress: '',
      isLoadingAlternateDownloadAddress: false,

      tip: [],

      imageNumber: 1,
      isAdvancedOptionsVisible: true,
      uploadOptionSelected: ['RATIO_1_1'], // 必须是数组，这里给了个默认值

      padRightRatio: 50,
      padBottomRatio: 50,

      enablePromptOptions: [{'text': '启用提示词', 'value': 'ENABLE_PROMPT', 'disabled': false}],
      enablePromptValue: [],  //实际数组里最多只有一个元素

      outputImageWidth: null,
      outputImageHeight: null,

      prompt: null,
      promptCount: 0,
      promptCountLimit: 800
    }
  },
  computed: {
    maxFiles() { //一次批处理最大允许上传的图片数
      return 8;
    },
    padLeftRatio() {
      return 100 - this.padRightRatio
    },
    padTopRatio() {
      return 100 - this.padBottomRatio
    },
    isMultipleUploading() {
      common.setSeoFooterElementVisible(true)
      return this.multipleUploading;
    }
  },
  created() {  // 模板渲染成html前调用
    // 设置最近访问的产品 URL
    this.LocalStorage.setLastAccessRoute('/imageoutpaint');
    //设置生成数量
    this.imageNumber = this.isQuantityMembership() ? 2 : 1
    // 设置封面
    UploadCommon.setBannerImage(this, 'product-cover/imageoutpaint-free-min.gif', 'product-cover/imageoutpaint-vip-min.gif')
  },
  mounted() {  // 模板渲染成html后调用
  },
  updated() {
  },
  components: {FilePond},
  methods: {
    onInit: function () {
      // 每次调用 this.$router.go(0) 类似方法路由到此组件都会触发此方法，导致创新新的 FilePond 实例，
      // 以至于服务器端返回的 websocket 消息无法绑定到 html 页面上，因为 self 参数已经变了在页面上。
      // 所以这里我把  self 作为全局变量来定义了！这样 doConnect() 方法中的 self 始终是最新的了！
      // 服务器端返回数据回显到页面也不会不显示了。
      self = this;
      self.message = null;
      self.messageType = null;

      // 设置上传组件
      FilePond2.setOptions({
        computable: true,
        server: {
          process: (fieldName, file, metadata, load, error, progress, abort) => {
            self.filePondError = error;
            // 自定义上传，参考 Process 这节：https://pqina.nl/filepond/docs/api/server/

            /* 触发控件显示 "百分比进度“
            const request = new XMLHttpRequest();
            request.open('POST', 'serverUrl');

            // 应在调用 load 之前调用 progress 方法将进度更新为100%
            // 设置 computable=false 切换到无限模式
            request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
            };
             */

            /*
            // 触发控件显示 "错误信息"，有reset按钮
            error('上传出错');
            // 触发控件显示 "已上传"，无reset按钮
            load();
            // 触发控件显示 "上传已取消"，有reset按钮
            abort();
             */

            // 警告：若调用 error、abort 前控件已经 disable 了，那么调用后 reset 按钮将无法点击

            // 1. 直接还没上传就报错情况
            if (file.customProgress) {
              const prog = file.customProgress;
              switch (prog.status) {
                case 'abort':
                  abort();
                  break;
                case 'error':
                  error(prog.message);
                  break;
              }
              return;
            }

            // 2. 分辨率自动降低到服务器端的限制，并给用户提示
            let notTiffFormat = file.type !== 'image/tiff'
            if (notTiffFormat) {
              ImageFile.reduceImageResolution(file, reduceImageWidth, reduceImageHeight).then((resolutionReduceFileInfo) => {
                let newFile = resolutionReduceFileInfo.file
                let isResolutionReduced = resolutionReduceFileInfo.isReduced;

                // 图片预处理的消息提醒
                if (isResolutionReduced) {
                  let msg = `您上传的图片分辨率大于 ${reduceImageWidth}x${reduceImageHeight} 乘积，已降到 ${resolutionReduceFileInfo.width}x${resolutionReduceFileInfo.height}。`
                  this.Common.toastInfo(this.$bvToast, '提示', msg); //显示此消息客户反而有困惑
                }

                UploadCommon.uploadToAliyun('image_outpaint_upload', fieldName, newFile, metadata, load, error, progress, abort)
              })
            } else {
              UploadCommon.uploadToAliyun('image_outpaint_upload', fieldName, file, metadata, load, error, progress, abort)
            }
          }
        },
        labelFileProcessingError: (status) => {
          // 替换错误 lable 上的消息
          return status.body ? status.body : '上传出错';
        }
      });

      UploadCommon.startConnecting(self, productCategory);
      UploadCommon.monitorUserLoginChangeRealTime();
      self.showMemberTips();
    },
    onWarning: function (status) {
      // 此事件一旦发生，则早于 onInitFile 事件
      if (status.body === 'Max files') {
        if (this.getLoginUser() && this.getLoginUser().loginName) {
          this.multipleUploading = true;
          this.showMessage('GENERIC_BATCH_UPLOAD_ERROR');
          this.batchUploadProgressMessage = '一次最多上传 ' + this.maxFiles + ' 张';
        } else {
          this.showMessage('GENERIC_ERROR', '免费用户上传限制 1 张');
        }
      }
    },
    onInitFile: function (file) {
      const pond = this.$refs.pond;
      const fileLenght = pond.getFiles().length;

      // 1. 添加 batchId、重置数据
      if (!file.getMetadata("batchId")) {
        // 生成批处理 ID，用来标记同一批次 File
        let batchId = this.Common.generateUuid();

        // 归到同一批次
        for (var i = fileLenght - 1; i >= 0; i--) {
          const fi = pond.getFiles()[i];
          fi.setMetadata('batchId', batchId);
          fi.setMetadata('batchSize', fileLenght);

          // batch 信息不能放 fi.file，否则在 FilePond2.server.process() 中获取存在丢失几率
          //fi.file['batchId'] = batchId;
          //fi.file['batchSize'] = fileLenght;
        }

        // 重置数据
        this.message = '';
        this.messageType = '';
        this.isShowResetButton = false;
        this.alternateDownloadAddress = '';

        // 2. 显示批处理上传框（服务器端完成所有图片上传后，会反馈信息以重置 multipleUploading 值）
        this.multipleUploading = true;
        this.showMessage('GENERIC_BATCH_UPLOAD_PROGRESS');
        this.batchUploadProgressMessage = '正在上传 0/' + fileLenght;

        // 3. 初始化校验成功数
        this.checkSuccessedCount = 0;
        this.checkFailedCount = 0;
        this.isPondBatchAborting = false;
      }

      // 2. 免费版上传限制
      const processCount = fileLenght;
      if (processCount > 1 && !this.isQuantityMembership()) {
        let outputImageNumber = processCount * this.imageNumber;
        if (outputImageNumber > 1) {
          this.showErrorMessage("免费版单次限制生成 1 张，请单张上传并设置生成张数为1");
          //this.batchUploadProgressMessage = ;
          this.isPondBatchAborting = true;
          return;
        }
      }
      if (processCount > 1 && this.isQuantityMembership()) {
        let outputImageNumber = processCount * this.imageNumber;
        if (outputImageNumber > this.maxFiles) {
          this.showErrorMessage(`会员单次限制生成 ${this.maxFiles} 张，请减少上传或调小生成张数`);
          //this.batchUploadProgressMessage = ;
          this.isPondBatchAborting = true;
          return;
        }
      }

      // 3. 自定义尺寸必须设置宽、高
      if (this.uploadOptionSelected.indexOf('CUSTOM') !== -1) {
        if (!this.outputImageWidth || !this.outputImageHeight) {
          this.showErrorMessage(`请自定义宽、高`);
          this.isPondBatchAborting = true;
          return;
        }
      }

    },
    onAddfile: function (error, file) {
      const pond = this.$refs.pond;

      // 若批处理中有检查失败的文件，则后续不再检查，直接在这终止
      if (this.isPondBatchAborting) return;

      // 提示：若多张图一起传，则两张图的 onInitFile() 方法都执行完毕后，才会执行 onAddfile()。
      UploadCommon.checkFile(maxFileSizeMBOfAnonymousUser, maxFileSizeMBOfMembership, null, error, file).then((file) => {
        this.checkSuccessedCount++;
        if (this.checkSuccessedCount === pond.getFiles().length) {
          // 触发自定义上传
          this.$refs.pond.processFiles();
        }
      }, () => {
        this.checkFailedCount++;
      })
    },
    onProcessfile: function (status, file) {
    },
    onError: function (status, file) {
    },
    onRemovefile: function () {
      // 批处理用户直接跳过
      if (this.isQuantityMembership()) return;

      this.message = '';
      this.messageType = '';
      this.isShowResetButton = false;
    },
    getLoginUser: function () {
      return this.$root.$refs.RouteMenu.loginUser;
    },
    isQuantityMembership: function () {
      const loginUser = this.getLoginUser();
      return loginUser ? loginUser.isQuantityMembership : false;
    },
    getQuantityMembershipPoints: function () {
      const loginUser = this.getLoginUser();
      return loginUser ? loginUser.quantityMembershipPoints : 0;
    },
    setCustomProgress: function (file, status, message) {
      file.file.customProgress = {status: status, message: message};

      // 延迟原因：若在 onInitFile() 中调用，pond 还没初始化好导报错
      const self = this;
      setTimeout(function () {
        self.$refs.pond.processFile();
      }, 100);
    },
    // 注意：此函数会 remove pond 文件致触发 onRemovefile 事件中删除 message 消息，导致用户可能会看不到下载链接。除非延迟1秒显示，但不建议。
    // 此产品中由于主要使用 multip upload 自动以控件，所以去掉了 onRemovefile，目前不会导致 下载链接 丢失了。
    resetUpload: function () {
      const pond = this.$refs.pond;
      if (!pond) return;

      // 删除所有 File
      const fileLenght = pond.getFiles().length;
      for (var i = fileLenght - 1; i >= 0; i--) {
        const file = pond.getFiles()[i];
        // 会触发 onRemovefile 事件，但此产品中由于主要使用 multip upload 自动以控件，所以未设置 onRemovefile。
        pond.removeFile(file.id);
      }

      this.message = '';
      this.messageType = '';
      this.isShowResetButton = false;
      this.multipleUploading = false;
      this.batchUploadProgressMessage = '';
    },

    onClickItem(value) {
      let $target = $(event.target)
      $target.closest('.outpaint-method').find('.item').removeClass('active')
      let $item = $target.hasClass('item') ? $target : $target.closest('.item')
      $item.addClass('active')

      // 尺寸只能单选
      this.uploadOptionSelected = []
      this.uploadOptionSelected.push(value)

      // 不是自定义尺寸，则清空宽、高
      if (value !== 'CUSTOM') {
        this.outputImageWidth = null
        this.outputImageHeight = null
      }
    },
    onCustomWidthBlur(event) {
      let value = event.target.value
      if (!value) return
      if (!this.isNumberIn1To3840(value)) {
        this.outputImageWidth = 3840
      }
    },
    onCustomHeightBlur(event) {
      let value = event.target.value
      if (!value) return
      if (!this.isNumberIn1To3840(value)) {
        this.outputImageHeight = 3840
      }
    },
    isNumberIn1To3840(value) {
      // 定义正则表达式，匹配 1-3840 的范围
      const regex = /^(1|[2-9]|\d{2,3}|1\d{3}|2\d{3}|3[0-7]\d{2}|3800|38[0-3]\d|3840)$/;

      // 检查输入值是否是数字字符串并符合范围
      return regex.test(value);
    },

    doDownloadFile() {
      UploadCommon.doDownloadFile()
    },
    loadAlternateDownloadAddress() {
      UploadCommon.loadAlternateDownloadAddress()
    },
    doAlternateDownloadFile() {
      UploadCommon.doAlternateDownloadFile()
    },
    makeToast(variant = null) {
      UploadCommon.makeToast(variant)
    },
    showMemberTips: function () {
      const self = this;
      const tips = [
        '[1] 建议多生成几张，挑选最佳结果',
        '[2] 批量扩图简单方便，支持手机传',
        '[3] 图片超过目标分辨率将先被缩小',
        '[4] 原图匹配宽高比则各方向扩30%'
      ];

      let myindex = self.Common.randomIntRange(0, 1);
      self.tip = tips[myindex];
      myindex += 1;

      setInterval(function () {
        if (myindex >= tips.length) myindex = 0;
        self.tip = tips[myindex++];
      }, 15000)
    },
    showMessage: function (type, message, isShowResetBtn) {
      this.messageType = type;
      this.message = message;
      this.isShowResetButton = !!isShowResetBtn;

      if (type === 'GENERIC_ERROR' || type === 'GENERIC_BATCH_UPLOAD_ERROR') {
        this.isShowResetButton = true;
      }
    },
    showErrorMessage: function (message, isShowResetBtn) {
      this.showMessage('GENERIC_ERROR', message, isShowResetBtn);
    },
    toggleShowAdvancedOptions: function () {
      this.isAdvancedOptionsVisible = !this.isAdvancedOptionsVisible;
    },
    routeToPointPackage: function () {
      this.$router.push({path: '/package'});

      // 滚动
      setTimeout(function () {
        let targetElement = document.getElementById('quantity-pack-title');
        targetElement.scrollIntoView(true);
      }, 100)
    },
    uploadOptionChange: function (selectedValues) {
      if (selectedValues.length === 0) {
        this.prompt = null
        this.promptCount = 0
      }
    },
    clearPrompt() {
      this.prompt = ''
    },
    promptKeyDown() {
    },
    promptKeyUp() {
      let val = $(event.target).val()
      this.promptCount = val.length
      if (this.promptCount > this.promptCountLimit) {
        this.prompt = val.substring(0, this.promptCountLimit)
        this.promptCount = this.promptCountLimit
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.file-upload {
  color: #333639;
  /*background-color: #1e2430;*/
  min-height: 27rem;
  overflow-x: hidden;
}

.pond-container, .advanced-container {
  width: 28rem;
}

.pond {
  width: 28rem;
  height: 4rem;
  margin: 0 auto;
  /*opacity: 0;*/
  transition: opacity 1s .25s;
}

.pond-multiple {
  width: 100%;
  height: 4rem;
  margin: 0 auto;
  /* opacity: 0; */
  transition: opacity 1s 0.25s;
}

.upload-options {
  display: inline-block;
  //margin-top: 18px;
  font-size: 0.875rem;
  margin-top: 4px;
  margin-bottom: 10px;
  font-weight: 300;
  /*由于最后一个checkbox lable 是左对齐的，导致右边会有点空，所以将整个group margin-left 点*/
  margin-left: 10px;
  color: #4f4f4f;
}

.response-message {
  font-size: 14px;
  text-align: center;
  margin-top: 18px;
}

.error-icon {
  margin-right: 5px;
}

.filepond--drop-label {
  font-weight: 400;
  text-align: center;
  overflow: hidden;
}

.filepond--drop-label > label {
  /*color: #c7ccd8;*/
}

.filepond-browse {
  text-decoration: underline;
  text-decoration-color: #babdc0;
  cursor: pointer;
}

.fileupload-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.download-link {
  cursor: pointer;
}

.download-link:hover {
  cursor: pointer;
}

.reset-upload {
  color: #727a79;
  text-decoration-line: underline;
  cursor: pointer;
}

.reset-upload:hover {
  color: #009991;
}

.title {
  color: #444;
  font-size: 30px;
}

.desc {
  font-size: 14px;
  color: #8f8f8f;
  display: none;
}

.tips {
  font-size: 13px;
  color: #8f8f8f;
  margin: 5px 0 10px 0;
  line-height: 18px;
}

.link-button {
  margin-left: 10px;
  color: #c18c00;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
}

.link-button:hover {
  color: #ce9c19;
  text-decoration: underline;
}

.effect-img {
  width: 26rem;
  height: 177px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  /*width: 350px;*/
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 1rem;
}

@media only screen and (max-width: 768px) {
  .effect-img {
    width: 95%;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 1rem;
  }
}

.c1 {
  /*display: flex;*/
  display: none;
  align-items: center;
  padding: 6px;
}

.c2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  //手机端内容靠上点比较合适，大拇指正好落在上传控件处
  margin-top: 33%;
}

.c3 {
  display: block;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.c4 {
  flex-grow: 1;
}

.c5 {
  background: #f2f2f2;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.email-icon {
  height: 45px;
}

.ads {
  margin-top: 70px;
  font-size: 13px;
  text-align: center;
}

.ad-link {
  cursor: pointer;
  color: #8f8f8f;
}


a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

.error-message {
  color: #db2828;
}

.error-message-underline {
  color: #db2828;
  text-decoration: underline;
  cursor: pointer;
}

.warning-message {
  /*color: #856404;*/
}

.error-info {
  width: 90%;
  height: 4rem;
  margin: 0 auto;

  position: absolute;
  top: 60px;
  font-size: 14px;
  line-height: 26px;

  background: #fff;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);

  padding: 15px 8px;
}

.b-tooltip .arrow {
  display: none;
}

.b-tooltip .tooltip-inner {
  color: #fff;
  background-color: #8d7353;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  border-right-color: #dc3545;
}

.b-toast-danger.b-toast-solid .toast {
  background-color: #fff;
}

.b-toast-danger .toast .toast-header {
  color: #721c24;
  /*background-color: rgba(248, 215, 218, 0.85);*/
  background-color: #fcedee;
  border-bottom-color: rgba(245, 198, 203, 0.85);
}

.b-toast-danger .toast {
  /*background-color: rgba(252, 237, 238, 0.85);*/
  background-color: #fff;
  border-color: rgba(245, 198, 203, 0.85);
  color: #721c24;
}

.urgent-notice {
  /*color: #db2828;*/
  color: green;
  font-size: 12px;
  padding-top: 30px;
}

.advanced-container {
  padding-bottom: 2px;

  .generated-image-number-container {
    display: inline-block;
    margin-bottom: 12px;

    .generated-image-number-wrapper {
      display: flex;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      margin-bottom: 2px;
      color: #555;
      font-size: 14px;

      .value {
        margin: 0 10px 0 0;
      }

      .input {
        width: 33px;
      }
    }
  }

  .style-container {
    display: inline-block;
    margin-left: 30px;

    .style-wrapper {
      display: flex;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      margin-bottom: 2px;
      color: #555;
      font-size: 14px;

      .label {
        white-space: nowrap;
      }

      .style-preview {
        display: inline-flex;
        align-items: center;
        width: 100%;
        overflow: hidden;

        .image-wrapper {
          display: flex;
          position: relative;

          img {
            width: 22px;
            height: 24px;
            margin-right: 4px;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            border: 1px solid #e1e1e1;
          }

          .img-remove {
            display: inline-block;
            cursor: pointer;

            .icon-trash-2 {
              position: absolute;
              right: 10px;
              top: 6px;
              font-size: 11px;
              color: #fff;
            }

            .icon-delete-bg {
              background-color: #4E4E4E49;
              position: absolute;
              height: 20px;
              width: 20px;
              right: 5px;
              top: 1px;
              border-radius: 3px;
              -webkit-border-radius: 3px;
            }
          }
        }

        .add-style {
          cursor: pointer;

          img {
            width: 27px;
            height: 27px;
            border: none;
          }
        }
      }
    }
  }
}

.fade-container {
  display: flex;
  flex-direction: column;
}

.outpaint-method {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2px;
  margin-bottom: 12px;

  .item {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    height: 23px;
    background-color: #fafafa;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
    color: #0f0f0f;
    line-height: 20px;
    margin: 3px;
    min-width: 12px;
    cursor: pointer;
    border-radius: 8px;
    -webkit-border-radius: 8px;
  }

  .item:hover {
    //color: #2d89f5;
    //background-color: #f6faff;
  }

  .item.active {
    color: #f6faff;
    background-color: #2d89f5;
  }

  .custom-size-container {
    display: flex;

    .custom-width {
      width: 60px !important;
      height: 19px;
      margin-left: 8px;
      margin-right: 8px;
    }

    .custom-height {
      width: 60px !important;
      height: 19px;
    }
  }

  .form-control {
    border: none;
  }

  .form-control:focus {
    //border-color: transparent;
    box-shadow: none;
  }

  .form-control-sm {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem 0 .25rem .4rem;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 2px;
  }
}

.prompt-section {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 2px;
  position: relative;
  margin-bottom: 20px;

  .prompt-input {
    width: 27rem;
  }

  .prompt-wrapper {

    textarea {
      border: none;
      box-shadow: none !important;
      resize: none;

      //box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
    }

    .icon-trash-2 {
      cursor: pointer;
    }

    /* 整个滚动条 */
    textarea::-webkit-scrollbar {
      width: 10px;
    }

    /* 滚动条的滚动区域（轨道） */
    textarea::-webkit-scrollbar-track {
      border-radius: 1px;
    }

    /* 滚动条的可拖拽部分（滑块） */
    textarea::-webkit-scrollbar-thumb {
      background: #bbbbbb;
      border-radius: 10px;
      visibility: visible; /* 影藏滚动条样式 */
      border: solid 2px #fff; /* 将 width 边框设白色看起来像有 padding 效果，改滚动条宽度就改这里即可 */
    }
  }
}

.thumbnail-preview-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 25px;

  //border: 1px solid #e1e1e1;
  //border-radius: 12px;
  //-webkit-border-radius: 12px;

  .thumbnail-container {
    display: inline-block;
    margin: 10px 10px;
    border: 1px solid #e1e1e1;
    border-radius: 12px;
    -webkit-border-radius: 12px;

    .thumbnail {
      border-radius: 12px;
      -webkit-border-radius: 12px;
    }
  }
}

//input[type="number"] {
//  -moz-appearance: textfield; /* Firefox */
//  appearance: textfield;
//}
//
//input[type="number"]::-webkit-inner-spin-button,
//input[type="number"]::-webkit-outer-spin-button {
//  -webkit-appearance: none; /* Safari */
//  margin: 0;
//}

@media only screen and (min-width: 601px) {
  .c2 {
    margin-top: 135px;
  }
}

@media only screen and (max-width: 601px) {
  .pond-container, .advanced-container {
    width: 99%;
  }
  .pond {
    width: 100%;
    height: 4rem;
    margin: 0 auto;
    /*opacity: 0;*/
    transition: opacity 1s .25s;
  }
  .upload-options {
    margin-bottom: 15px;
  }
  .prompt-section {
    .prompt-input {
      width: 99%;
    }
  }
}

@media only screen and (max-width: 370px) and (max-height: 567px) {
  .title {
    font-size: 25px;
  }
}

</style>
