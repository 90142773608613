<template>
  <section class="promotion">
    <div class="top">
      <div style="display:inline-block;font-weight:500;">
        <img :src="`${this.Server.imageRootUrl}/logo_120x120.webp`" height="25" width="25"
             style="margin-right:8px;position:relative;top:-2px;">
        <a href="https://www.tpqxb.com/imageenlarge" style="text-underline: none;color:#222;font-size:16px;">图片清晰吧</a>
      </div>
    </div>

    <div class="product-list">
      <h1 class="title">产品列表</h1>

      <div class="grid-container">
        <div class="item1">
          <img alt="图片清晰吧" class="img"
               v-bind:src="tpqxbImageUrl" height="250px">
        </div>
        <div class="item2">
          <b-card title="1. 图片清晰吧" border-variant="light" class="right-card">
            <b-card-text>简单好用的照片变清晰、人像修图工具，通过放大图片、高清修复，让你的照片更加精美！</b-card-text>
            <b-button href="https://www.tpqxb.com" variant="primary">免费使用</b-button>
          </b-card>
        </div>

        <div class="item3">
          <b-card title="2. 图片修复吧" border-variant="light" class="left-card">
            <b-card-text>方便的在线修图工具，可快速去水印，去除图片上的文字，或者修复老照片等！</b-card-text>
            <b-button href="https://www.tpqxb.com/cleaner" variant="primary">免费使用</b-button>
          </b-card>
        </div>
        <div class="item4">
          <img alt="图片修复吧" class="img" :src="`${this.Server.imageRootUrl}/cleaner-effect-1.gif`" height="250px">
        </div>

        <div class="item5">
          <img alt="一键抠图吧" class="img" :src="`${this.Server.imageRootUrl}/imagematting-cycle.gif`" height="250px">
        </div>
        <div class="item6">
          <b-card title="3. 一键抠图吧" border-variant="light" class="right-card">
            <b-card-text>无论是人像、动物、植物还是物品，只需上传图片，就可以在线抠图，一键去背景，轻松制作透明PNG图片！
            </b-card-text>
            <b-button href="https://www.tpqxb.com/imagematting" variant="primary">免费使用</b-button>
          </b-card>
        </div>

        <div class="item7">
          <b-card title="4. 人物卡通化" border-variant="light" class="right-card">
            <b-card-text>有趣的照片变漫画工具，想知道怎么把照片变成漫画吗？那你一定要试试这款神奇的软件！</b-card-text>
            <b-button href="https://www.tpqxb.com/humananime" variant="primary">免费使用</b-button>
          </b-card>
        </div>
        <div class="item8">
          <img alt="人物卡通化" class="img" :src="`${this.Server.imageRootUrl}/humananime-free.gif`" height="250px">
        </div>

        <div class="item9">
          <img alt="图片压缩吧" class="img" :src="`${this.Server.imageRootUrl}/imagecompression1.png`" height="250px">
        </div>
        <div class="item10">
          <b-card title="5. 图片压缩吧" border-variant="light" class="right-card">
            <b-card-text>一款强大的在线图片压缩工具，压缩高达 70% 以上，保证图片质量依然不错，几乎不损失画质！
            </b-card-text>
            <b-button href="https://www.tpqxb.com/imagecompression" variant="primary">免费使用</b-button>
          </b-card>
        </div>

      </div>
    </div>

    <div class="company-profile">
      <h1 class="title" style="margin-top:0;margin-bottom:60px;">公司简介</h1>

      <b-card :img-src="`${this.Server.imageRootUrl}/company.jpg`" img-alt="图片清晰吧公司简介"
              img-left img-height="220px" img-width="400px" style="height: 220px;overflow:hidden">
        <b-card-text style="padding:10px 10px;height: 220px;overflow:hidden">
          柏华软件是一家专注于提供AI图片处理服务的互联网公司，成立于2021年，总部位于苏州。<br/>
          公司的愿景是让每一张图片都能展现出最佳的效果，让每一个用户都能享受到AI技术带来的便利和乐趣。<br/>
          公司致力于不断创新和优化产品，为用户提供更加优质和专业的AI图片处理服务。<br/><br/>
          <div>
            联系我们：suzhouwanghu1120@163.com
          </div>
        </b-card-text>
      </b-card>
    </div>

    <div class="footer">
    </div>

  </section>
</template>

<script>

import $ from 'jquery';

export default {
  name: 'Promotion',
  data() {
    return {
      tpqxbImageUrl: `${this.Server.imageRootUrl}/imageenlarge-free.png`
    };
  },
  setup() {
  },
  created() {  // 模板渲染成html前调用
    document.getElementById('home').style.display = 'none';
  },
  mounted() {  // 模板渲染成html后调用
    const self = this;
    this.$root.$refs.RouteMenu.hideMe();

    setTimeout(function () {
      self.tpqxbImageUrl = `${this.Server.imageRootUrl}/imageenlarge-face.png`
    }, 8000);
    setTimeout(function () {
      self.tpqxbImageUrl = `${this.Server.imageRootUrl}/imageenlarge-free.png`
    }, 16000);
  },
  updated() {

  },
  methods: {}
}

</script>

<style lang="scss" scoped>

.promotion {

  .top {
    padding: 20px 20px;
    border-bottom: 1px solid #e1e1e1;
    margin: 0 -15px; // 抵消掉外部组件的 margin
  }

  .title {
    text-align: center;
    margin: 80px 0 100px 0;
  }

  .item1, .item3, .item5, .item7, .item9 {
    text-align: right;
  }

  .item2, .item4, .item6, .item8, .item10 {
    text-align: left;
    margin-left: 25px;
  }

  .right-card {
    width: 400px;
    display: inline-block;
    height: 250px;
    margin-top: 36px;
  }

  .left-card {
    width: 400px;
    display: inline-block;
    height: 250px;
    text-align: left;
    margin-top: 36px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 15px;
    background-color: transparent;
    padding: 15px;
  }

  .grid-container > div {
    //background-color: #f6f6f6;
    padding: 0;
    margin-bottom: 100px;
    //font-size: 23px;
  }

  .img {
    max-height: 290px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
  }

  .company-profile {
    //font-size: 12px;
  }

  .footer {
    padding: 30px 30px;
    width: 100%;
    //border-top: 1px solid #dadada;
    text-align: center;
    margin: 0 -15px; // 抵消掉外部组件的 margin
  }

}

</style>
