<!--忘记密码-->

<template>
  <section class="forget-password-form">

    <div style="text-align:center;margin-bottom:10px;">
      <img v-bind:src="imageUrl" height="80" width="80">
    </div>
    <div class="form-title">
      <div>重置密码</div>
      <div style="font-size:13px;margin-top:8px;"></div>
    </div>
    <b-form inline>

      <div class="form-group">
        <b-input-group v-bind:prepend="nationCode">
          <b-form-input type="number" id="inline-form-input-name" v-bind:placeholder="phonePlaceholder"
                        v-model="phone"
                        onkeyup="value=value.replace(/[^\d]/g,'')">
          </b-form-input>
        </b-input-group>
      </div>

      <div class="form-group">
        <b-input-group>
          <b-form-input type="password" id="password" v-model="password" placeholder="新密码">
          </b-form-input>
        </b-input-group>
      </div>

      <div class="form-group">
        <b-input-group>
          <b-form-input type="password" id="passwordConfirm" v-model="passwordConfirm" placeholder="确认密码">
          </b-form-input>
        </b-input-group>
      </div>

      <div class="form-group">
        <b-input-group append-html="<span class='forget-password-form-request-captcha'>获取验证码</span>">
          <b-form-input type="number" id="captcha" placeholder="验证码"
                        v-model="captcha"
                        v-on:keyup="onKeyUpCaptcha($event)">
          </b-form-input>
        </b-input-group>
      </div>

      <p class="msg-group" v-html="msg"></p>

      <div class="form-bottom">
        <a class="cancle-btn" variant="primary" v-on:click="cancleBtn">取消</a>
        <b-button class="forget-password-submit" variant="primary" v-on:click="changePassword">确定</b-button>
      </div>

    </b-form>
  </section>
</template>

<script>
import $ from 'jquery';
import RegexToolbox from '../utils/regex.toolbox'
import Server from "../utils/server";
import common from "@/utils/common";
import UploadCommon from "@/utils/upload.common";

export default {
  name: 'ForgetPasswordForm',
  data() {
    return {
      imageUrl: '',
      nationCode: '+86',
      phone: '',
      captcha: '',
      password: '',
      passwordConfirm: '',
      remark: '',
      msg: ''
    };
  },
  computed: {
    phonePlaceholder() {
      common.setSeoFooterElementVisible(false)
      return '手机号';
    },
  },
  setup() {
  },
  created() {  // 模板渲染成html前调用
    document.getElementById('home').style.display = 'none';
    UploadCommon.setPrivateImage(this, 'imageUrl', 'site/logo_120x120.webp')
  },
  mounted() {  // 模板渲染成html后调用
    const self = this;

    this.$root.$refs.RouteMenu.hideMe();
    this.msg = '';

    $('.forget-password-form-request-captcha').on('click', function (e) {
      self.requestPhoneCaptcha();
    });

    // 自动填充缓存的手机号
    const cachedLoginName = self.LocalStorage.getLoginName();
    if (cachedLoginName) {
      this.phone = cachedLoginName;
    }

    // 自动填充URL上的手机号（优先级高于上面的）
    const loginName = self.Common.getQueryVariable(location.href, 'loginName');
    if (loginName) {
      this.phone = loginName;
    }
  },
  updated() {
  },
  components: {},
  methods: {
    cancleBtn: function () {
      this.$root.$refs.RouteMenu.showMe()

      const lastAccessRoute = this.LocalStorage.getLastAccessRoute()
      switch (lastAccessRoute) {
        case "/cleaner":
          location.href = Server.getCloudServiceAddress(true) + '/cleaner';
          break;
        default:
          this.$router.push({path: lastAccessRoute})
      }
    },
    requestPhoneCaptcha: function () {
      this.msg = '';
      if (!this.phone || this.phone.length === 0) {
        this.msg = '请输入手机号';
        return;
      }
      if (!RegexToolbox.isPhoneLiteralLikely(this.phone)) {
        this.msg = '手机号格式错误';
        return;
      }
      if (!this.password || this.password.length === 0) {
        this.msg = '新密码不能为空';
        return false;
      }
      if (this.password.length < 6) {
        this.msg = '新密码太短，至少6位数';
        return false;
      }
      if (this.passwordConfirm.length === 0) {
        this.msg = '确认密码不能为空';
        return false;
      }
      if (this.password !== this.passwordConfirm) {
        this.msg = '两次密码输入不一致';
        return false;
      }

      this.requestCaptchaAjax();
    },
    requestCaptchaAjax: function () {
      const self = this;

      this.captcha = '';
      this.msg = '<span style="color:#5f6368;">正在请求验证码......</span>';

      const captchaReq = {
        nationCode: this.nationCode.substring(1),  //例如：86
        phone: this.phone
      };

      setTimeout(function () {
        const url = Server.getCloudServiceAddress() + '/account/requestCaptcha';
        const resp = self.Ajax.post(url, captchaReq, false, '', '', '', '');
        if (resp.success) {
          self.msg = '<span style="color:#28a745;">验证码已发送</span>';
        } else {
          self.msg = Server.getAjaxResponseMessage(resp, '获取失败，请一小时后再试');
        }
      }, 500);
    },
    changePassword: function () {
      this.msg = '';

      if (!this.phone || this.phone.length === 0) {
        this.msg = '请输入手机号';
        return;
      }
      if (!RegexToolbox.isPhoneLiteralLikely(this.phone)) {
        this.msg = '手机号格式错误';
        return;
      }
      if (!this.password || this.password.length === 0) {
        this.msg = '新密码不能为空';
        return;
      }
      if (this.password.length < 6) {
        this.msg = '新密码太短，至少6位数';
        return;
      }
      if (this.passwordConfirm.length === 0) {
        this.msg = '确认密码不能为空';
        return false;
      }
      if (this.password !== this.passwordConfirm) {
        this.msg = '两次密码输入不一致';
        return false;
      }
      if (!this.captcha || this.captcha.length === 0) {
        this.msg = '请输入验证码';
        return;
      }
      if (!RegexToolbox.isPhoneCaptcha(this.captcha) || this.captcha.length !== 6) {
        this.msg = '验证码格式错误';
        return;
      }

      this.changePasswordAjax()
    },
    changePasswordAjax: function () {
      const self = this;

      this.msg = '';

      const req = {
        phone: this.phone,
        password: this.password,
        captcha: this.captcha
      };

      setTimeout(function () {
        const url = Server.getCloudServiceAddress() + '/account/changePassword';
        const resp = self.Ajax.put(url, req, false, '', '', '', '');

        if (resp.success) {
          const user = resp.data[0];
          if (user) {
            self.msg = '<span style="color:#28a745;">密码修改成功，3秒后跳到登录页......</span>';
            setTimeout(() => {
              self.$root.$refs.RouteMenu.showMe();
              self.$root.$refs.RouteMenu.gotoPage('login');
            }, 3000)
          } else {
            self.msg = Server.getAjaxResponseMessage(resp);
          }
        } else {
          self.msg = Server.getAjaxResponseMessage(resp);
        }
      }, 500);
    },
    onKeyUpCaptcha: function (e) {
      var el = e.currentTarget;
      el.value = el.value.replace(/[^\d]/g, '');
    }
  }
}

</script>

<style lang="scss" scoped>

.form-group {
  width: 100%;

  input {
    height: 45px;
  }
}

.form-inline .form-group {
  margin-bottom: 10px;
}

.form-inline .custom-select, .form-inline .input-group {
  width: 100%;
}

.input-group-text {
  background-color: transparent;
}

#captcha {
  border-right: 0;
}

.forget-password-form {
  margin: 0 auto;
  min-height: 450px;
  background: #fff;
  display: block;
  flex-shrink: 0;

  width: 100%;
  border: none;
  margin-top: 5%;
  padding: 20px 20px 21px;
}

.form-title {
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 1.5;
  color: #222;
  margin-bottom: 25px;
  margin-top: 8px;
}

.form-bottom {
  width: 100%;
  text-align: center;

  .cancle-btn {
    float: left;
    color: #1a73e8;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    top: 8px;
  }

  .cancle-btn:hover {
    color: #135ab6;
  }

  .forget-password-submit {
    float: right;
    cursor: pointer;
    padding: 5px 30px;
  }

}

.msg-group {
  width: 100%;
  height: 2em;
  margin-bottom: 0;
  font-size: 13px;
  color: #dc3545;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
}

.desc-group {
  color: #5f6368;
  margin-top: 10px;
  display: block;
  width: 100%;
}

.tips {
  color: #dc3545;
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
}

.btn-outline-primary {
  border-color: #05B4FD;
}

.btn-outline-primary:hover {
  background-color: #007bff;
  border-color: #05B4FD;
}

/*去掉input[type="number"]的默认样式*/
//-----------------------------------

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

//-----------------------------------

@media only screen and (min-width: 576px) {
  .forget-password-form {
    width: 400px;
    border: 1px solid #dadce0;
    border-radius: 8px;
    margin-top: 120px;
    padding: 58px 40px 25px;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 10px;
  }
}

</style>

<style>
.forget-password-form-request-captcha {
  cursor: pointer;
}

.forget-password-form-request-captcha:hover {
  color: #9c9c9c;
}
</style>
